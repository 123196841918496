// Colors
// -------------------------------------------------------------------------------------------------
$black40: rgba(0, 0, 0, 0.4);
$black45: rgba(0, 0, 0, 0.45);
$black85: rgba(0, 0, 0, 0.85);
$primary-color: #ff810a;
$secondary-color: #2e2e2e;
$green-color: #1fc784;
$light-green-color: #20d88e;

// Typography
// -------------------------------------------------------------------------------------------------

$thin-font: 'BMW-Thin', sans-serif;
$light-font: 'BMW-Light', sans-serif;
$regular-font: 'BMW-Regular', sans-serif;
$medium-font: 'BMW-Medium', sans-serif;
$bold-font: 'BMW-Bold', sans-serif;

// Media queries
// -------------------------------------------------------------------------------------------------
$media-mobile-small-max: 350;
$media-mobile-medium-max: 540;
$media-mobile-max: 767;
$media-tablet-min: 768;
$media-tablet-max: 1024;
$media-desktop-min: $media-tablet-max + 1;
$media-desktop-hd-min: 1920;
$media-desktop-small-max: 1300;

$media-mobile: '(max-width: #{$media-mobile-max}px)';
$media-mobile-medium: '(max-width: #{$media-mobile-medium-max}px)';
$media-mobile-small: '(max-width: #{$media-mobile-small-max}px)';
$media-mobile-landscape: '(max-width: #{$media-mobile-max}px) and (orientation: landscape)';
$media-mobile-portrait: '(max-width: #{$media-mobile-max}px) and (orientation: portrait)';

$media-tablet: '(min-width: #{$media-tablet-min}px) and (max-width: #{$media-tablet-max}px)';
$media-tablet-landscape: '(min-width: #{$media-tablet-min}px) and (max-width: #{$media-tablet-max}px) and (orientation: landscape)';
$media-tablet-portrait: '(min-width: #{$media-tablet-min}px) and (max-width: #{$media-tablet-max}px) and (orientation: portrait)';

$media-desktop: '(min-width: #{$media-desktop-min}px)';
$media-desktop-hd: '(min-width: #{$media-desktop-hd-min}px)';
$media-small-desktop: '(min-width: #{$media-desktop-min}px) and (max-width: #{$media-desktop-small-max}px)';

$media-non-mobile: '(min-width: #{$media-mobile-max}px)';
$media-non-desktop: '(max-width: #{$media-tablet-max}px)';

$layout-padding-top: 16px;
$layout-header-h: 78px;
$layout-footer-h: 100px;
$layout-side-padding: 30px;
$layout-side-padding-m: 23px;

$layout-header-h-mobile: 54px;
$layout-footer-h-mobile: 50px;
