@function vh($val, $base: 1080) {
  @return $val / $base * 100 + vh;
}

@function vh_m($val, $base: 690) {
  @return $val / $base * 100 + vh;
}

@function vw_d($val, $base: 1920) {
  @return $val / $base * 100 + vw;
}

@function vw($val, $base: 375) {
  @return $val / $base * 100 + vw;
}
