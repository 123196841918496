@import 'routes/client/styles/index.scss';

.second-section {
  padding: vw_d(190) vw_d(155) 0;

  @include screen('mobile') {
    position: relative;
    margin-top: vw(20);
    padding: 0 vw(15);
    z-index: 2;
  }

  .player-wrap {
    position: relative;
    width: 100%;

    .player {
      width: 100%;
      height: vw_d(893);

      @include screen('mobile') {
        margin-top: vw(15);
        height: vw(200);
      }
    }

    .info {
      right: vw_d(37);
      bottom: vw_d(62);

      @include screen('mobile') {
        right: vw(10);
        bottom: vw(63);
      }
    }
  }

  .under-player {
    position: relative;
    display: flex;
    justify-content: center;

    .video-cast {
      text-align: left;
      position: absolute;
      left: 0;
      top: vw_d(28);
      color: #fff;
      font-size: vw_d(13);
      line-height: vw_d(22);

      @include screen('mobile') {
        width: vw(310);
        text-align: center;
        top: vw(8);
        left: 50%;
        transform: translate(-50%, 0);
        font-size: vw(8);
        line-height: vw(12);
      }

      div {
        @include screen('mobile') {
          display: inline;
        }
      }
    }

    .video-controls {
      display: flex;
      flex-direction: column;
      align-items: center;

      .article-url {
        padding: vw_d(6) vw_d(56) vw_d(10) vw_d(14);
        margin-top: vw_d(36);
        border: 1px solid #fff;
        font-family: $regular-font;
        font-size: vw_d(26);
        color: #fff;
        text-align: center;

        @include screen('mobile') {
          margin-top: vw(64);
          padding: vw(4) vw(33) vw(7) vw(8);
          font-size: vw(15);
          line-height: vw(21);
        }

        svg {
          margin-right: vw_d(29);
          width: vw_d(15);
          height: vw_d(15);

          @include screen('mobile') {
            width: vw(9);
            height: vw(9);
            margin-right: vw(15);
          }
        }
      }

      .grey-line {
        @include screen('mobile') {
          margin: 0 auto;
          width: vw(342);
          height: 1px;
          margin-top: vw(39);
          background-color: #7070706a;
        }
      }
    }
  }

  .title {
    display: flex;
    justify-content: center;
    span {
      margin: 0 auto;
      padding: vw_d(10.9) vw_d(27.8) vw_d(16) vw_d(32);
      font-size: vw_d(35);
      line-height: vw_d(39);
      text-align: center;
      font-family: $regular-font;
      background-color: #e22718;
      color: #fff;

      @include screen('mobile') {
        padding: vw(2.3) vw(13) vw(5.3) vw(15);
        font-size: vw(22);
        line-height: vw(26);
      }
    }
  }
}

.episode-switcher {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-family: $regular-font;
    color: #fff;
    font-size: vw_d(20);

    @include screen('mobile') {
      font-size: vw(14);
      line-height: vw(19);
    }
  }

  .switchers {
    margin-top: vw_d(34);
    display: flex;
    align-items: center;

    @include screen('mobile') {
      margin-top: 0;
    }

    .switcher {
      cursor: pointer;
      margin-right: vw_d(23);
      height: vw_d(32);
      width: vw_d(104);
      background-color: #302f2e;
      font-size: vw_d(20);
      font-family: $regular-font;
      color: #fff;
      text-align: center;
      border-radius: vw_d(4);
      transition: all 0.3s ease;

      @include screen('mobile') {
        margin-right: vw(17);
        width: vw(83);
        height: vw(26);
        font-size: vw(15);
        border-radius: vw(4);
      }

      &:first-child {
        margin-right: 0;
      }
    }

    .active {
      background-color: #e02618;
    }
  }
}
