@import 'routes/client/styles/index.scss';

.videoPlayer {
  position: relative;

  .big-play {
    position: absolute;
    left: vw_d(32);
    bottom: vw_d(30);
    width: vw_d(68);
    height: vw_d(68);

    @include screen('mobile') {
      left: vw(24);
      bottom: vw(18);
      width: vw(34);
      height: vw(34);
    }

    svg {
      display: block;
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.player {
  object-fit: cover;

  &::-webkit-media-controls-panel {
    display: none;
  }

  &::-webkit-media-controls {
    display: none;
  }

  &::-webkit-media-controls-play-button {
    display: none;
  }

  &::-webkit-media-controls-volume-slider {
    display: none;
  }

  &::-webkit-media-controls-mute-button {
    display: none;
  }

  &::-webkit-media-controls-timeline {
    display: none;
  }

  &::-webkit-media-controls-current-time-display {
    display: none;
  }
}

.controls {
  position: absolute;
  display: flex;
  bottom: 0;
  padding: 0 vw_d(37) vw_d(24) vw_d(29);

  @include screen('mobile') {
    padding: 0 vw(11.6) vw(25.5) vw(0);
  }

  .mute {
    svg {
      width: vw_d(24);
      height: vw_d(18);

      @include screen('mobile') {
        width: vw(29);
        height: vw(22);
      }
    }
  }

  .timeline {
    cursor: pointer;
    direction: ltr;
    height: vw_d(2);
    width: vw_d(1370);
    margin-top: vw_d(11);
    margin-right: vw_d(32);

    @include screen('mobile') {
      width: vw(202);
      height: vw(2);
      margin-top: vw(14);
      margin-right: vw(18);
    }

    -webkit-appearance: none;
    border-radius: 5px;
    background-size: 0% 100%;
    background-image: linear-gradient(#ff1200);
    background-repeat: no-repeat;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      cursor: pointer;
      opacity: 0;
      transition: all 0.1s;
      background-color: #ff1200;
    }

    &::-moz-range-thumb {
      -webkit-appearance: none;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      cursor: pointer;
      opacity: 0;
      transition: all 0.1s;
      background-color: #ff1200;
    }

    &::-ms-thumb {
      -webkit-appearance: none;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      cursor: pointer;
      opacity: 0;
      transition: all 0.1s;
      background-color: #ff1200;
    }

    &::-webkit-slider-thumb:hover {
      background-color: #fff;
    }

    &:hover::-webkit-slider-thumb {
      opacity: 1;
    }

    &::-moz-range-thumb:hover {
      background-color: #fff;
    }

    &:hover::-moz-range-thumb {
      opacity: 1;
    }

    &::-ms-thumb:hover {
      background-color: #fff;
    }

    &:hover::-ms-thumb {
      opacity: 1;
    }

    &::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }

    &::-moz-range-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }

    &::-ms-track {
      -webkit-appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }
  }

  .current-time {
    cursor: default;
    margin-right: vw_d(7);
    color: #fff;
    font-size: vw_d(17);
    font-family: $regular-font;

    @include screen('mobile') {
      font-size: vw(14);
      margin-top: vw(4);
      margin-right: vw(5);
    }
  }

  .play {
    margin-right: vw_d(30);

    @include screen('mobile') {
      margin-right: vw(5);
    }

    svg {
      width: vw_d(27);
      height: vw_d(27);

      @include screen('mobile') {
        width: vw(27);
        height: vw(27);
      }
    }
  }
}

.hide-controls {
  display: none;
}
