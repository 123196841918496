@import 'routes/client/styles/index.scss';

.timeline-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .hover {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: vw_d(50);

    @include screen('mobile') {
      bottom: vw(30);
    }

    &.active-hover {
      @include screen('mobile') {
        .info {
          visibility: visible;
        }

        .flag {
          visibility: visible;
        }

        .active {
          animation: none;
        }
      }
    }

    .info {
      visibility: hidden;
      bottom: vw_d(160);
      min-width: vw_d(690);
      background-color: #fff;
      padding: vw_d(13) vw_d(20) vw_d(50) vw_d(19);
      color: #023568;
      font-size: vw_d(16);

      @include screen('mobile') {
        padding: vw(12) vw(22) vw(18) vw(8);
        font-size: vw(14);
        width: vw(337);
      }

      .title {
        font-family: $bold-font;
      }

      .first-subtitle {
        margin-top: vw_d(6);
        font-family: $regular-font;

        @include screen('mobile') {
          margin-top: vw_d(2);
        }
      }

      .second-subtitle {
        margin-top: vw_d(10);
        font-family: $regular-font;

        @include screen('mobile') {
          margin-top: vw_d(5);
        }
      }
    }

    .first-info-element {
      margin-right: vw_d(550);

      @include screen('mobile') {
        margin-right: vw(250);
      }
    }

    .second-info-element {
      margin-right: vw_d(60);
      @include screen('mobile') {
        margin-right: 0;
      }
    }

    .last-info-element {
      margin-right: vw_d(-550);

      @include screen('mobile') {
        margin-right: vw(-250);
      }
    }

    .flag {
      margin-top: vw_d(9);
      margin-left: vw_d(12);
      visibility: hidden;
      transition: visibility 0s, opacity 0.5s linear;

      @include screen('mobile') {
        margin-top: vw(7.5);
        margin-left: vw(12);
      }

      img {
        width: vw_d(82);
        height: vw_d(45);

        @include screen('mobile') {
          width: vw(64);
          height: vw(35);
        }
      }
    }

    .active {
      visibility: visible;
      transition: none;

      @include screen('mobile') {
        opacity: 1;
        animation: fadeInRight 0.3s ease-in-out forwards;
      }
    }
  }

  .pointer-wrap {
    position: relative;
    cursor: pointer;
    @include screen('desktop') {
      &:hover {
        & ~ .hover {
          .info {
            animation: fadeInRight 0.3s ease-in-out;
            visibility: visible;
          }

          .flag {
            animation: fadeInRight 0.3s ease-in-out;
            visibility: visible;

            @include screen('mobile') {
              animation: none;
            }
          }

          .active {
            animation: none;
          }
        }

        .pointer {
          background-color: #fff;

          .red-dot {
            display: none;
          }
        }

        .pointer-title {
          .date {
            font-family: $bold-font;
            font-size: vw_d(27);

            @include screen('mobile') {
              font-size: vw(20);
            }
          }
        }
      }
    }
    @include screen('tablet') {
      &:hover {
        & ~ .hover {
          .info {
            animation: fadeInRight 0.3s ease-in-out;
            visibility: visible;
          }

          .flag {
            animation: fadeInRight 0.3s ease-in-out;
            visibility: visible;

            @include screen('mobile') {
              animation: none;
            }
          }

          .active {
            animation: none;
          }
        }

        .pointer {
          background-color: #fff;

          .red-dot {
            display: none;
          }
        }

        .pointer-title {
          .date {
            font-family: $bold-font;
            font-size: vw_d(27);

            @include screen('mobile') {
              font-size: vw(20);
            }
          }
        }
      }
    }

    .pointer {
      margin-top: vw_d(9);
      width: vw_d(34);
      height: vw_d(35);
      border: 3px solid #fff;
      border-radius: 50%;
      transition: all 0.1s ease;
      display: flex;
      justify-content: center;
      align-items: center;

      @include screen('mobile') {
        margin-top: vw(5);
        width: vw(26);
        height: vw(26);
      }

      .red-dot {
        width: vw_d(9);
        height: vw_d(9);
        background-color: #e02618;
        border-radius: 50%;

        @include screen('mobile') {
          width: vw(9);
          height: vw(9);
        }
      }
    }

    .pointer-title {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      text-align: center;
      color: #fff;

      .date {
        margin-top: vw_d(4);
        letter-spacing: vw_d(7);
        font-family: $regular-font;
        font-size: vw_d(20);
        line-height: vw_d(30);

        @include screen('mobile') {
          font-family: $regular-font;
          font-size: vw(14);
          letter-spacing: vw(7);
          line-height: vw(34);
        }
      }

      .car-name {
        font-family: $light-font;
        font-size: vw_d(16);
        line-height: vw_d(26);
        width: vw_d(156);

        @include screen('mobile') {
          font-size: vw(14);
          line-height: vw(18);
          width: vw(120);
        }
      }
    }
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(40%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes fadeFlag {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
