@import 'routes/client/styles/index.scss';

.fourth-section {
  position: relative;
  margin-top: vw_d(71);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include screen('mobile') {
    margin-top: vw(30);
  }

  .title {
    position: relative;
    z-index: 2;
    text-align: center;
    color: #fff;
    font-family: $regular-font;
    font-size: vw_d(40);

    @include screen('mobile') {
      max-width: vw(230);
      margin-top: vw(30);
      font-family: $light-font;
      font-size: vw(22);
      line-height: vw(25);
    }
  }

  .background {
    z-index: 1;
    position: absolute;
    height: vw_d(553);

    @include screen('mobile') {
      height: vw(473);
    }

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .arrow-left {
    position: absolute;
    z-index: 3;
    left: vw_d(225);
    bottom: vw_d(206);

    @include screen('mobile') {
      display: none;
    }

    svg {
      width: vw_d(9);
      height: vw_d(16);
    }
  }

  .arrow-right {
    position: absolute;
    z-index: 3;
    right: vw_d(225);
    bottom: vw_d(206);

    @include screen('mobile') {
      display: none;
    }

    svg {
      width: vw_d(9);
      height: vw_d(16);
    }
  }

  .inactive {
    pointer-events: none;
    opacity: 0.7;
  }

  .timeline {
    position: relative;
    display: flex;
    z-index: 2;
    width: 74%;
    overflow-x: auto;
    overflow-y: hidden;

    @include screen('mobile') {
      overflow-x: auto;
      width: 100%;
    }

    &::-webkit-scrollbar {
      height: 0px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #3e3e3e;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .slider {
      position: relative;
      display: flex;

      @include screen('mobile') {
      }

      .item {
        display: flex;
        align-items: center;

        &:first-child {
          .white-line {
            width: vw_d(135);

            @include screen('mobile') {
              width: vw(50);
            }
          }
        }

        &:last-child {
          .white-line {
            &:last-child {
              @include screen('mobile') {
                width: vw(50);
              }
            }
          }
        }

        .white-line {
          margin-top: vw_d(222);
          width: vw_d(154);
          height: 1px;
          background-color: #fff;
          border-right: vw_d(6) solid #000;
          border-left: vw_d(6) solid #000;

          @include screen('mobile') {
            margin-top: vw(323);
            width: vw(111);
            border-right: vw(4) solid #000;
            border-left: vw(4) solid #000;
          }
        }

        .slide {
          min-height: vw_d(460);
          margin-bottom: vw_d(200);
          justify-content: flex-end;

          @include screen('mobile') {
            min-height: vw(500);
            margin-bottom: vw(150);
          }
        }
      }
    }
  }
}

.arrow-icon {
  width: vw_d(9);
  height: vw_d(16);
}
