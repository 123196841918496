@import 'routes/client/styles/index.scss';

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0e0101;
  padding: vw_d(22) vw_d(254) vw_d(12) vw_d(275);

  @include screen('mobile') {
    padding: vw(14) vw(23) vw(14) vw(11);
  }

  .right-part {
    display: flex;
    align-items: center;

    span {
      margin: 0 vw_d(20);
      font-size: vw_d(11);
      color: #fff;

      @include screen('mobile') {
        display: none;
        // font-size: vw(11);
        // margin: 0 vw(20);
      }
    }

    .ynet-logo {
      height: vw_d(40);

      @include screen('mobile') {
        display: none;
        // height: vw(24);
      }

      img {
        height: 100%;
      }
    }

    .logo {
      cursor: pointer;
      height: vw_d(52);

      @include screen('mobile') {
        height: vw(31);
      }
    }
  }

  .left-part {
    display: flex;
    align-items: center;
  }

  .icon {
    cursor: pointer;

    svg {
      width: vw_d(27);
      height: vw_d(27);

      @include screen('mobile') {
        height: vw(28);
        width: vw(28);
      }
    }
  }

  .shares {
    display: flex;
    align-items: center;

    .share-icon {
      width: vw_d(26);
      height: vw_d(26);
      margin-right: vw_d(12);
    }

    .envelope {
      display: flex;
      align-items: center;
      font-family: $regular-font;
      font-size: vw_d(11);
      color: #fff;

      span {
        margin-left: vw_d(8);
      }
    }
  }

  .devider {
    height: vw_d(53);
    width: vw_d(1);
    margin-left: vw_d(20);
    background-color: #fff;
    opacity: 0.28;

    @include screen('mobile') {
      height: vw(35);
      margin-left: vw(20);
    }
  }

  .socials {
    margin-left: vw_d(4);
    display: flex;
    align-items: center;

    @include screen('mobile') {
      margin-left: vw(2);
    }

    .icon {
      margin-left: vw_d(15);

      @include screen('mobile') {
        margin-left: vw(15);
      }
    }

    .facebook {
      display: flex;
      align-items: center;
    }

    .instagram {
      display: flex;
      align-items: center;
      font-family: $regular-font;
      font-size: vw_d(11);
      color: #fff;

      @include screen('mobile') {
        font-size: vw(11);
      }

      span {
        margin-left: vw_d(8);

        @include screen('mobile') {
          margin-left: vw(8);
        }
      }
    }
  }
}

.minified {
  @include screen('mobile') {
    margin-right: vw(14.5);
  }

  &.open {
    .shares {
      visibility: visible;
      opacity: 1;
      transform: scale(1);

      @include screen('mobile') {
        position: absolute;
        top: vw(-118);
      }
    }
  }

  .shares {
    position: relative;
    transition: all 0.25s ease-in-out;
    visibility: hidden;
    opacity: 0;

    @include screen('mobile') {
      position: absolute;
      top: vw(-118);
      left: vw(-98);
      width: vw(201);
      height: vw(233);
      padding: vw(130) vw(25) 0 vw(25);
      padding-top: vw(130);
      transition: all 0.3s ease;
      box-shadow: 0 12px 40px 0 rgba(21, 37, 154, 0.23);
      transform: scale(0);
    }
  }

  .icon {
    overflow: visible;
    width: vw(26);

    &.facebook {
      margin-top: vw(30);
      margin-right: vw(-29);
    }

    &.whatsup {
      margin-top: vw(65);
      margin-right: vw(-36.5);
    }
  }

  .envelope {
    margin-top: vw(10);
    margin-right: vw(40);
  }

  .toggleBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    border-radius: 50%;
    transition: 0.3s;
    overflow: visible;

    &.open {
      margin: 0;

      svg {
        width: vw(28);
        height: vw(28);
      }
    }

    svg {
      width: vw(28) !important;
      height: vw(28) !important;
    }

    span {
      margin-left: vw(8);
      margin-bottom: vw(1);
      color: #fff;
    }
  }
}
