@import 'routes/client/styles/index.scss';

.article {
  display: block;
  width: vw_d(773);

  @include screen('mobile') {
    width: vw(345);
    padding-bottom: vw(30);
    border-bottom: 1px solid #7070706a;
  }

  .img-wrapper {
    width: vw_d(773);
    height: vw_d(509);

    @include screen('mobile') {
      width: vw(345);
      height: vw(227);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .texts {
    margin-top: vw_d(30);
    text-align: right;
    color: #fff;
    font-family: $regular-font;

    @include screen('mobile') {
      margin-top: vw(9);
    }

    span {
      display: block;
    }

    .title {
      font-size: vw_d(27);

      @include screen('mobile') {
        font-size: vw(19);
      }
    }

    .subtitle {
      display: block;
      font-family: $light-font;
      margin-top: vw_d(16);
      font-size: vw_d(15);
      line-height: vw_d(25);

      @include screen('mobile') {
        margin-top: vw(10);
        font-size: vw(15);
        line-height: vw(18);
        max-width: vw(330);
      }

      span {
        display: inline;
        font-family: $medium-font;
      }
    }
  }
}
