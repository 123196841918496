@import 'routes/client/styles/index.scss';

.popup {
  z-index: -1;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease;
  background-color: #000000a6;

  @include screen('mobile') {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .content {
    max-width: vw_d(1400);

    @include screen('mobile') {
      max-width: 95%;
    }

    .close {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      svg {
        cursor: pointer;
        margin-left: vw_d(-5);
        margin-bottom: vw_d(12);
        width: vw_d(41);
        height: vw_d(41);

        @include screen('mobile') {
          width: vw(31);
          height: vw(31);
        }
      }
    }

    img {
      width: 100%;
      max-height: vw_d(614);
      object-fit: contain;
      border: vw(1) solid #fff;

      @include screen('mobile') {
        max-height: vw(264);
      }
    }
  }
}

.show {
  z-index: 100;
  visibility: visible;
  opacity: 1;
}
