@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: 'BMW-Bold';
  src: url('/src/routes/client/assets/fonts/BMWTypeNextProTTHebrew-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BMW-Medium';
  src: url('/src/routes/client/assets/fonts/BMWTypeNextProTTHebrew-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BMW-Regular';
  src: url('/src/routes/client/assets/fonts/BMWTypeNextProTTHebrew-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BMW-Light';
  src: url('/src/routes/client/assets/fonts/BMWTypeNextProTTHebrew-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BMW-Thin';
  src: url('/src/routes/client/assets/fonts/BMWTypeNextProTTHebrew-Thin.ttf');
  font-weight: normal;
  font-style: normal;
}
