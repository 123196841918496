@import 'routes/client/styles/index.scss';

.gallery-section {
  width: 100%;
  max-width: vw_d(1610);
  margin: 0 auto;
  padding: vw_d(100) 0 vw_d(147);

  @include screen('mobile') {
    width: 100%;
    max-width: inherit;
    padding: vw(71) 0 vw(27);
  }

  .title {
    padding-bottom: vw_d(50);
    font-size: vw_d(40);
    line-height: 1;
    color: #fff;
    text-align: center;
    font-family: $regular-font;
    @include screen('mobile') {
      width: vw(260);
      margin: 0 auto;
      padding-bottom: vw(22);
      font-size: vw(20);
      line-height: vw(26);
    }
  }

  .gallery-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include screen('mobile') {
      overflow-x: scroll;
      display: block;
      white-space: nowrap;
      margin-right: vw(18);
    }

    .images-wrapper {
      display: flex;
      height: vw_d(546);
      @include screen('mobile') {
        display: inline-block;
        font-size: 0;
        margin-left: vw(10);
        height: vw(184);
      }
      .image {
        cursor: pointer;
        display: block;
        width: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        @include screen('mobile') {
          display: inline-block;
        }
      }

      .box {
        @include screen('mobile') {
          display: inline-block;
        }
      }

      &.v1 {
        width: vw_d(405);
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: vw_d(33);
        @include screen('mobile') {
          width: auto;
          margin-bottom: 0;
        }
        .image {
          @include screen('mobile') {
            width: vw(311);
          }
          &.first {
            height: vw_d(328);
            @include screen('mobile') {
              height: vw(184);
            }
          }
          &.second {
            height: vw_d(184);
            @include screen('mobile') {
              height: vw(184);
              margin-right: vw(10);
            }
          }
        }
      }
      &.v2 {
        width: vw_d(469);
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: vw_d(33);
        @include screen('mobile') {
          width: auto;
          margin-bottom: 0;
        }
        .image {
          @include screen('mobile') {
            width: vw(311);
          }
          &.first {
            height: vw_d(328);
            @include screen('mobile') {
              height: vw(184);
            }
          }
          &.second {
            height: vw_d(184);
            @include screen('mobile') {
              height: vw(184);
              margin-right: vw(10);
            }
          }
        }
      }
      &.v3 {
        width: vw_d(670);
        height: vw_d(546);
        margin-bottom: vw_d(33);
        @include screen('mobile') {
          width: vw(311);
          margin-bottom: 0;
        }
        .image {
          height: vw_d(546);
          width: vw_d(670);
          @include screen('mobile') {
            height: vw(184);
            width: vw(311);
          }
        }
      }
      &.v4 {
        width: vw_d(1175);
        height: vw_d(546);
        margin-bottom: vw_d(33);
        @include screen('mobile') {
          height: vw(184);
          width: vw(311);
          margin-bottom: 0;
        }
        .image {
          width: vw_d(1175);
          height: vw_d(546);
          @include screen('mobile') {
            height: vw(184);
            width: vw(311);
          }
        }
      }

      &.v5,
      &.v6 {
        height: vw_d(328);
        margin-bottom: 0;
        @include screen('mobile') {
          height: vw(184);
        }
        .image {
          height: vw_d(328);
          @include screen('mobile') {
            height: vw(184);
          }
        }
      }
      &.v5 {
        width: vw_d(405);
        @include screen('mobile') {
          width: vw(311);
        }
        .image {
          width: vw_d(405);
          @include screen('mobile') {
            width: vw(311);
          }
        }
      }
      &.v6 {
        width: vw_d(735);
        @include screen('mobile') {
          width: vw(311);
        }
        .image {
          width: vw_d(735);
          @include screen('mobile') {
            width: vw(311);
          }
        }
      }
    }
  }
}
