@import 'routes/client/styles/theme.scss';
@import 'routes/client/styles/mixins.scss';
.app {
  position: relative;
  min-height: 100vh;
  @include screen('mobile') {
    min-height: inherit;
  }
}

.info-popup {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
