@import 'routes/client/styles/index.scss';

.third-section {
  margin-top: vw_d(129);
  padding: 0 vw_d(155);
  display: flex;
  flex-direction: column;
  align-items: center;

  @include screen('mobile') {
    margin-top: vw(33);
    padding: 0;
  }

  .title {
    font-family: $regular-font;
    font-size: vw_d(40);
    color: #fff;

    @include screen('mobile') {
      font-size: vw(20);
    }
  }

  .articles {
    margin-top: vw_d(76);
    padding-bottom: vw_d(180);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: vw_d(168);

    @include screen('mobile') {
      margin-top: vw(37);
      padding-bottom: vw(63.5);
      flex-direction: column;
      row-gap: vw(41);
    }
  }
}
