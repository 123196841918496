@import 'routes/client/styles/index.scss';

.first-section {
  position: relative;
  width: 100%;

  .background {
    z-index: 1;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: vw_d(720);
    opacity: 0.8;
    @include screen('mobile') {
      height: vw(700);
    }

    video {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
      // max-height: vw_d(720);
      // width: 100%;
      // opacity: 0.55;
      // pointer-events: none;
    }
  }

  .content {
    position: relative;
    z-index: 2;
    opacity: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .center {
      margin-top: vw_d(240);
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include screen('mobile') {
        margin-top: vw(147);
      }

      img {
        margin: 0 auto;
        width: vw_d(529);
        height: vw_d(108);

        @include screen('mobile') {
          width: vw(329);
          height: vw(67);
        }
      }

      .title {
        font-size: vw_d(48);
        letter-spacing: vw_d(11);
        color: #fff;
        font-family: $light-font;

        @include screen('mobile') {
          display: block;
          max-width: vw(347);
          font-size: vw(25);
          line-height: vw(38);
        }
      }

      .mobile-logo {
        display: none;
        @include screen('mobile') {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: vw(3);

          img {
            width: vw(50);
            height: auto;
            margin-right: vw(7);
          }
          span {
            font-size: vw(14);
            color: #fff;
          }
        }
      }

      .subtitle {
        margin-top: vw_d(12);
        max-width: vw_d(1416);
        font-size: vw_d(22);
        color: #fff;
        font-family: $light-font;

        @include screen('mobile') {
          display: block;
          max-width: vw(334);
          font-size: vw(15);
          line-height: vw(20);
          margin-top: vw(17);
        }
        span {
          font-family: $bold-font;
        }
      }

      .arrowDown {
        cursor: pointer;
        margin-top: vw_d(92);

        @include screen('mobile') {
          margin-top: vw(80);
        }

        svg {
          width: vw_d(33);
          height: vw_d(34);

          @include screen('mobile') {
            width: vw(24);
            height: vw(24);
          }
        }
      }
    }
  }
}
