@import 'routes/client/styles/index.scss';

.info {
  position: relative;

  .title {
    background-color: #e22718;
    padding: vw_d(6) vw_d(8);
    opacity: 0.83;
    color: #fff;
    font-size: vw_d(17);
    font-family: $regular-font;

    @include screen('mobile') {
      max-width: vw(327);
      padding: vw(1) vw(4) vw(1) vw(4);
      font-size: vw(12);
      line-height: vw(14);
    }
  }

  .subtitle {
    margin: vw_d(40.6) 0 vw_d(24);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: vw_d(69);
    color: #fff;
    font-size: vw_d(21);
    line-height: vw_d(27);
    font-family: $light-font;

    @include screen('mobile') {
      margin: vw(13.7) 0 vw(20);
      min-height: vw(132);
      font-size: vw(15);
      line-height: vw(17);
      font-family: $regular-font;
    }
  }
}
